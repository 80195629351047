import React from "react";
import { Helmet } from 'react-helmet';

const Meta = ({ title, script }) => {
    return (
        <Helmet
            title={title}
            script={[{
                "innerHTML": script
            }]}/>
    );
}

export default Meta;
