import React from "react";
import logoPng from "./images/logo.png";

const Navigation = () => {
    return (
        <nav className="navbar navbar-default navbar-expand-lg fixed-top custom-navbar">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon ion-md-menu"/>
            </button>
            <div className="container">
                <img src={logoPng} className="img-fluid nav-logo-desktop" alt="Company Logo" />
                    <ul className="navbar-nav ml-auto nav-right" data-easing="easeInOutExpo" data-speed="1250" data-offset="65">
                    <li className="nav-item nav-custom-link">
                        <p>Оформи полис ОСАГО за 10 минут.</p>
                    </li>
                    </ul>
            </div>
        </nav>
    );
}

export default Navigation;
