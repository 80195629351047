import React from "react";
import alphaStrah from "../lp_one/images/alpha_strah.png"
import ingosstrah from "../lp_one/images/ingosstrah.png"
import maks from "../lp_one/images/maks.png"
import renaissance from "../lp_one/images/renaissance.png"
import rgs from "../lp_one/images/rgs.png"
import soglasie from "../lp_one/images/soglasie.png"
import tinkoff from "../lp_one/images/tinkoff.png"
import vsk from "../lp_one/images/vsk.png"
import zetta from "../lp_one/images/zetta.png"

const InsuranceCompanies = () => {
    return (
        <section id="marketing" style={{backgroundColor: '#f6f8f9'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="content-box">
                            <span>расчеты из</span>
                            <h2>9 страховых</h2>
                            <p>Не требуется никому звонить или стоять в очередях. Просто заполняете данные и сами выбираете с какой страховой будете работать</p>
                        </div>
                    </div>
                    <div className="col-md-9 hidden-xs">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <img src={alphaStrah} className="img-fluid" alt="alphaStrah" />
                                </div>
                                <div className="col-sm">
                                    <img src={ingosstrah} className="img-fluid" alt="ingosstrah" />
                                </div>
                                <div className="col-sm">
                                    <img src={maks} className="img-fluid" alt="maks" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <img src={renaissance} className="img-fluid" alt="renaissance" />
                                </div>
                                <div className="col-sm">
                                    <img src={rgs} className="img-fluid" alt="rgs" />
                                </div>
                                <div className="col-sm">
                                    <img src={soglasie} className="img-fluid" alt="soglasie" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <img src={tinkoff} className="img-fluid" alt="tinkoff" />
                                </div>
                                <div className="col-sm">
                                    <img src={vsk} className="img-fluid" alt="vsk" />
                                </div>
                                <div className="col-sm">
                                    <img src={zetta} className="img-fluid" alt="zetta" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InsuranceCompanies;
