import React from "react";

const InsuranceCompanies = () => {
    return (
        <section id="testimonials" style={{backgroundColor: 'white'}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="content-box">
                            <span>Заполняете</span>
                            <h2>Данные по авто</h2>
                            <p>Ваши данные у себя мы не храним!</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="content-box">
                            <span>Полис</span>
                            <h2>Оплачиваете</h2>
                            <p>Оплата производится через платежную систему страховой!</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="content-box">
                            <span>Получаете</span>
                            <h2>Полис на почту</h2>
                            <p>В течении полу часа полис придет на указанную Вами почту</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InsuranceCompanies;
