import React from "react";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">
                        <h5>Внимание!</h5>
                        <p>Если Вы оплатили полис и Вам не пришло сообщение с полисом, просьба связаться с выбранной вами страховой. Так как оплата проходит через платежные системы страховых компаний</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
