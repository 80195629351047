import logo from './logo.svg';
import './App.css';
import LpOne from "./pages/LpOne";

function App() {
  return (
    <LpOne />
  );
}

export default App;
