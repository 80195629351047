import React from "react";
import Meta from "./components/Meta";
import Navigation from "./components/lp_one/Navigation";
import Hero from "./components/lp_one/Hero";
import InsuranceCompanies from "./components/lp_one/InsuranceCompanies";
import Footer from "./components/lp_one/Footer";
import "./components/lp_one/css/main.css";
import ThreeSteps from "./components/lp_one/ThreeSteps";

const LpOne = () => {
    return (
    <>
        <Meta title="Введите номер автомобиля, узнайте стоимость и оформите ОСАГО онлайн" script={`var B2CModuleConfig = {
                    module: 'eosago',
                    host: 'https://widget.agentapp.ru/widgets/eosago/',
                    assetsIn: 'https://widget.agentapp.ru/widgets/eosago/',
                }`}/>
        <Navigation />
        <Hero />
        <ThreeSteps />
        <InsuranceCompanies />
        <Footer />
    </>
    );
}

export default LpOne;
