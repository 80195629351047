import React from "react";
import ScriptTag from "react-script-tag";

const Hero = () => {
    return (
        <section id="hero">
            <div className="container">
                <div className="row">
                    <div className="content-box hero-content">
                        <ScriptTag id="app-b2c-module-root" src="https://widget.agentapp.ru/widgets/eosago/assets/b2c-module.loader.js" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
